<template>
  <v-app>
    <dashboard-core-app-bar />

    <dashboard-core-drawer v-if="!isFoodTickerManager" />

    <dashboard-core-view />
  </v-app>
</template>

<script>
import { FOOD_TICKET_MANAGER } from "@/shared/config";
import user from "@/store/auth";

export default {
  name: "DashboardIndex",

  components: {
    DashboardCoreAppBar: () => import("./components/core/AppBar"),
    DashboardCoreDrawer: () => import("./components/core/Drawer"),
    DashboardCoreView: () => import("./components/core/View")
  },

  data: () => ({
    isFoodTickerManager: false
  }),
  computed: {
    getUserRole() {
      return user.getters.getUserRole;
    }
  },
  mounted() {
    if (this.getUserRole === FOOD_TICKET_MANAGER) {
      this.isFoodTickerManager = true;
    }
  },
  created() {
    this.$vuetify.theme.themes[this.isDark ? "dark" : "light"].primary =
      "#ff9800";
  }
};
</script>
